import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { StyleSidebarMenuItem } from './style';

type SidebarMenuProps = {
  href: string;
  title: string;
  icon?: ReactNode;
  fontIcon?: string;
  hasBullet?: boolean;
  iconType?: string;
  hideMenuTitleToggle?: boolean;
  externalLink?: boolean;
};

type WithChildren = {
  children?: ReactNode;
};

export function getCurrentUrl(pathname: string) {
  return pathname?.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);
  // NOSONAR
  // if (pathname === '/' && url === '/dashboard') {
  //   return true;
  // }
  const allUrl = current?.split('/').filter((item) => item !== '').map((item) =>  `/${item}`);

  if (current === url || current?.indexOf(url) > -1 || allUrl?.includes(url)) {
    return true;
  }
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  return false;
}
const SidebarMenuItem: FC<SidebarMenuProps & WithChildren> = ({
  children,
  href,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  iconType,
  hideMenuTitleToggle = false,
  externalLink = false,
}) => {
  const pathname = usePathname();
  const isActive = checkIsActive(pathname, href);

  return (
    <StyleSidebarMenuItem hidetitle={hideMenuTitleToggle.toString()}>
      <Link
        className={
          isActive === true
            ? ' menu-link px-2.5 py-3 without-sub active'
            : ' menu-link px-2.5 py-2.5 without-sub'
        }
        href={href}
        target={
          externalLink === true ? '_blank' : '_self'
        }
        rel={
          externalLink === true ? 'noopener noreferrer' : ''
        }
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && <span className="menu-icon">{icon}</span>}
        {fontIcon && iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        {/* <span className=' '>{title}</span> */}
        {hideMenuTitleToggle === true ? <span className=" ">{title}</span> : ''}
        <span className="tooltip-menu">{title}</span>
      </Link>
      {children}
    </StyleSidebarMenuItem>
  );
};

export default SidebarMenuItem;
