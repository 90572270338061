import { Button } from '@/components/elements';
import Image from 'next/image';

interface Props {
  canvaTitle: string;
  canvaUrl: string;
  canvaSize: string;
  canvaImg: string;
}

const CardCanvaTemplate = ({canvaTitle, canvaUrl, canvaSize, canvaImg}: Props) => {
  return (
    <div className='flex gap-4 py-2 items-center'>
      <div className='grow-0'>
        <Image src={canvaImg} alt="canva logo" width={100} height={100} loading='lazy' />
      </div>
      <div className='grow'>
        <p>{canvaTitle}</p>
        <small>{canvaSize}</small>
      </div>
      <div className='grow-0 '>
        <Button
          size='sm'
          onClick={() =>
            window.open(canvaUrl, '_blank', 'noopener,noreferrer')
          }
        >
          ดาวโหลด
        </Button>
      </div>
    </div>
  );
};

export default CardCanvaTemplate;
